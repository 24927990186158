import { gsap, Linear } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
// import { ScrollScene } from 'scrollscene'
import { SmoothScroll } from "./lib/SmoothScroll";

import "@lottiefiles/lottie-player";

export default function animation() {


    (function gsapAnimate() {

        gsap.registerPlugin(ScrollTrigger);

        const fadeElements = function() {
            const elements = document.querySelectorAll('.fade-animate');
            if(!elements) return;
  
  
      
            elements.forEach(el => {
              const fadeTimeline = gsap.timeline({
                scrollTrigger: {
                  trigger: el,
                  start: `top 100%`
                }
              });
      
              fadeTimeline.fromTo(el, .4, {translateY: 30, alpha: 0}, {translateY: 0, alpha: 1, delay: 0.3, ease: 'cubic-bezier(.19,1,.22,1)'});
            });
          };
          fadeElements();

          const staggerElements = function() {
            const elements = document.querySelectorAll('.stagger-animate');
            if(!elements) return;
  
  
      
            elements.forEach(el => {
              const fadeTimeline = gsap.timeline({
                scrollTrigger: {
                  trigger: el,
                  start: `top 100%`,
                  // markers: true
                }
              });
              fadeTimeline.to(el.children, .8, {translateY: 1, alpha: 1, ease: 'cubic-bezier(.19,1,.22,1)', stagger: 0.4});
            });
          };
          staggerElements();

    
      })();  

}