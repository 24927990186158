// eslint-disable-line
import Swiper, { Navigation, Pagination } from "swiper";

export default function sliders() {

  (function simpleSlider() {
    // Install modules
    Swiper.use([Navigation, Pagination]);
    const swiper = new Swiper(".studio-slider", {
      slidesPerView: 3,
      spaceBetween: 32,
      loop: true,
      watchOverflow: true,
      navigation: {
        nextEl: ".studio-slider__arrows-next",
        prevEl: ".studio-slider__arrows-prev",
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 32,
        }
      },
    });
  })();


}