// eslint-disable-next-line
import Pristine from "pristinejs";
import "./lib/jquery.maskedinput";
import 'magnific-popup';

export default function modals() {


    $('input[type="tel"]').mask("+7 (999) 999-99-99");
    // (function inputPhoneMask() {
    //     $('input[type="tel"]').mask("+7 (999) 999-99-99", {
    //       completed() {
    //         const formGroup = $(this).closest(".form-group");
    //         formGroup[0].classList.remove("has-danger");
    //         formGroup.find(".pristine-error").hide();
    //       },
    //     });
    //   })();

    $(document).ready(function() {
      $('.open-modal').magnificPopup({
        type: 'inline',
        preloader: false
      });
    });


    (function formValidation() {
        const formList = document.querySelectorAll("[data-form-validate]");
    
        formList.forEach((form) => {
          const validation = new Pristine(form);
          const successMessage = form.querySelector(".form-success__message");
          // const wrapper = form.closest('.request__wrapper') || form.closest('.request-modal')
          form.addEventListener("submit", function (e) {
            // console.log('submit');
            e.preventDefault();
            const formData = new FormData(this);
            const valid = validation.validate();

            if (valid) {
              $.ajax({
                // dataType: "json",
                type: form.method,
                url: form.action,
                data: formData,
                cache: false,
                processData: false,
                contentType: false,
                success(data) {
                    form.reset();
                    $.magnificPopup.open({
                      items: {
                          src: '#thanksModal'
                      }
                    }, 0);
                    // console.log('success!');
                    // wrapper.classList.remove('loader');
                },
                error(request, status, error) {},
              });
            } else if(successMessage) {
                successMessage.style.display = "none";
              }
          });
        });
      })();

}