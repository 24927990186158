/* eslint-disable func-names */
// import "./lib/selectize";
// import ionRangeSlider from "ion-rangeslider";
import videojs from 'video.js';
import 'videojs-youtube';

export default function controls() {


    (function videoPlayers() {
        const videos = document.querySelectorAll('.video-js');
        if(!videos) return;
    
        videos.forEach(video => {
            const videoPlayer = videojs(video, {
                fluid: true,
                volume: 0.7,
            });
        
            videoPlayer.on('loadedmetadata', function() {
                this.el().classList.add('vjs-loaded')
                this.el().querySelector('.vjs-big-play-button .vjs-control-text').textContent = this.el().dataset.title
            });
        });
    })();

    

    (function navToggle() {
        const btn = document.querySelector('.mobile-nav__toggle');
        // const menuList = document.querySelector('.mobile-menu-list');
        const header = document.querySelector('.header');

        btn.addEventListener('click', function() {
            header.classList.toggle('menu-open')
        });

        // menuList.addEventListener('click', function(e) {
        //     if(e.target.classList.contains('.mobile-nav__list')) {
        //         header.classList.toggle('menu-open')
        //     }
            
        // })


    })();

    // (function headerSticky() {
    //     const {body} = document;
    //     const scrollUp = "scroll-up";
    //     const scrollDown = "scroll-down";
    //     const header = document.querySelector('.header')
    //     let lastScroll = 0;

    //     window.addEventListener("scroll", () => {
    //         if(header.classList.contains('menu-open')) return;
    //         const currentScroll = window.pageYOffset;
    //         if (currentScroll <= 300) {
    //             body.classList.remove(scrollUp);
    //             return;
    //         }
            
    //         if (currentScroll > lastScroll && currentScroll > 300 && !body.classList.contains(scrollDown)) {
    //             // down
    //             body.classList.remove(scrollUp);
    //             body.classList.add(scrollDown);
    //         } else if (currentScroll < lastScroll && currentScroll > 300 && body.classList.contains(scrollDown)) {
    //             // up
    //             body.classList.remove(scrollDown);
    //             body.classList.add(scrollUp);
    //         }
    //         lastScroll = currentScroll;
    //     });
    // })();


    // (function getContent() {

    //     function evalJSFromHtml(html) {
    //       const newElement = document.createElement('div');
    //       newElement.innerHTML = html;
        
    //       const scripts = newElement.getElementsByTagName("script");
    //       for (let i = 0; i < scripts.length; ++i) {
    //         const script = scripts[i];
    //         // eslint-disable-next-line no-eval
    //         eval(script.innerHTML);
    //       }
    //     }

    //     $('.ajax_load').on('click', function(e) {
    //         e.preventDefault();
    //         const activeClass = this.dataset.activeClass || 0
    //         if(this.classList.contains(activeClass)) return;
    //         const loadURL = this.dataset.ajaxFrom
    //         if(!loadURL) return;
    //         const appending = this.dataset.ajaxAppend || 0
    //         const pageTo = this.dataset.ajaxTo || null
    //         const hideIt = this.dataset.ajaxHide || null
    //         const wrapper = document.querySelector(pageTo) || null
    //         const hideThis = document.querySelector(hideIt)
    //         if(wrapper) {
    //             wrapper.classList.add('loader')
    //         }
    //         if(activeClass) {
    //             this.parentElement.querySelectorAll('.ajax_load').forEach(n => n.classList.toggle(activeClass, n === e.target));
    //         }
    //         // this.classList.add('active')
        
    //         $.ajax({
    //         type: "GET",
    //         url: loadURL,
    //         dataType: "html",
    //         success(data) {
                
    //             if(appending === '1') {
    //             wrapper.insertAdjacentHTML('beforeend', data);
    //             wrapper.classList.remove('loader')
    //             } else {
    //             wrapper.innerHTML = data;
    //             wrapper.classList.remove('loader')
    //             }
    //             evalJSFromHtml(data);
    //                 if(hideThis) {
    //                 hideThis.style.display = 'none';
    //             }

    //         }
    //         });
    //     });
    // })();

}